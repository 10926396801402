$(function() {
    /* faq START */
    $('.accordion dd').hide();
    $('.accordion dt').click(function() {
        $(this).next('dd').toggle();
    });
    /* faq END */

    /* tabs START */
    $('.faq .content, .product .section').hide();
    $('.faq #tab1, .product #tab1').show();

    $('.tabs a').click(function(e) {
        e.preventDefault();

        var selected = $(this).attr('href');
        $('.faq > .content, .product .section').hide();
        $('#' + selected).show();
    });
    /* tabs END */

    /* dropdown START */
    $('.dropdown-toggle').click(function(e){
        e.preventDefault();
        
        $(this).toggleClass('on');
        $(this).next('.dropdown-menu').toggle();
    });

    $('.dropdown-item').click( function(e){
        e.preventDefault();

        var selected = $(this).text();
        $('.dropdown-toggle').text(selected).removeClass('on');
        $('.dropdown-menu').hide();
    });
    /* dropdown END */

    /* partner scroll START */
    $('.partner .tabs a').click(function(e){
        e.preventDefault();

        $('.partner .tabs a').removeClass('on');
        $(this).addClass('on');
        
        var selected = $(this).attr('href');
        var position = $('#' + selected).offset().top + 'px';
       
        $('html').animate({
            scrollTop: position 
        });
    });
    /* partner scroll END */

    /* footer dropdown START */
    $('.footer-dropdown').hover(function() {
        $('.footer-dropdown-menu').slideDown();
        $('.footer-toggle').addClass('on');
    }, function(){
        $('.footer-dropdown-menu').slideUp();
        $('.footer-toggle').removeClass('on');
    });
    /* footer dropdown END */

    /* asdie dropdown START */
    $('.aside-toggle').click(function(e){
        e.preventDefault();

        $(this).toggleClass('on');
        $(this).next('.aside-menu').toggle();
    });

    $('.aside-item').click( function(e){
        e.preventDefault();

        var selected = $(this).text();
        $('.aside-toggle').text(selected).removeClass('on');
        console.log('hello');
    });
    /* asdie dropdown END */

    /* product START */
    $('.sumnail-item').hover( function(e) {
        e.preventDefault();
        var sumnailPath = $(this).find('img').attr('src');
        $('.prd-img').attr('src', sumnailPath);
        $('.sumnail-item').removeClass('on');
        $(this).addClass('on');
    });
    /* product END */

    /* 모든 메뉴 보기 START */
    $('.btn-hamburger').click( function() {
        $('.navbar-modal').slideDown(700);
        $('.modal-backdrop').show();
    });

    $('.btn-hamburger-modal').click(function() {
        $('.navbar-modal').slideUp(700);
        $('.modal-backdrop').hide();
    });
    /* 모든 메뉴 보기 END */

    /* 메인 상단 배너 START */
    $('.btn-close-banner').click(function(){
        $('.top-banner').hide();
    });
     /* 메인페이지 상단 배너 END */

    /* 메인 패럴 START */
    function init() {
        var enInit = function() {
            $('.main-slider .en-prx').each(function(i) {
                setTimeout(function() {
                  $('.main-slider .en-prx').eq(i).addClass('on');
                }, 150 * (i + 1));
            });
        }

        setTimeout(enInit, 2000);

        setTimeout(function() {
            $('.main-slider .ko-prx').addClass('on');
        }, 4000);

        setTimeout(function() {
            $('.main-slider .btn-prx').addClass('on');
        }, 6000);
    }
    window.onload = init;
    /* 메인 패럴 END */
    
    /* 메인 납품 및 시공사례 START */
    $(".case-pic")
        .mouseenter(function() {
            $(this).css({
                backgroundSize: 120 + "%",
            });
        })
        .mouseleave(function() {
            $(this).css({
                backgroundSize:  100 + "%",
            });
        });
    /* 메인 납품 및 시공사례 END */
    
    /* gnb START */
    $('.navbar')
        .mouseenter(function() {
            $(this).addClass('on');
        })
        .mouseleave(function() {            
            $(this).removeClass('on');
        });    

    $('.nav-link').mouseenter(function() {
        $('.nav-link').removeClass('on');
        $(this).addClass('on');

        $(this).parents('.navbar').addClass('on');

    });

    $('.sub').mouseleave(function() {
        $('.navbar').removeClass('on');
    });

    var navOffset = $('.navbar').offset().top;
    $(window).scroll(function() {
        var wScroll = $(this).scrollTop();

        /* 네비 메뉴 START */
        if (wScroll >= navOffset) {
           $('.navbar').addClass('is-fixed');
		} else {
		   $('.navbar').removeClass('is-fixed');
        }
    });
    /* gnb END */

    /* 메인 본문 START */
    $(window).scroll(function() {
        var wScroll = $(this).scrollTop();

        if(wScroll >  $('.main-biz').offset().top - 500) {
            $('.main-biz .en-prx').addClass('on');

            setTimeout(function() {
                $('.main-biz .ko-prx').addClass('on');
            }, 600);
    
            setTimeout(function() {
                $('.main-biz .btn-prx').addClass('on');
            }, 1200);

            setTimeout(function() {
                $('.main-biz .en-prx-2').addClass('on');
            }, 1800);

            setTimeout(function() {
                $('.main-biz .ko-prx-2').addClass('on');
            }, 2200);
    
            setTimeout(function(){
                $('.main-biz .btn-prx-2').addClass('on');
            }, 2800);
        }

        if( wScroll > $('.main-client').offset().top - 500 ) {
            $('.cli-item-prx').each(function(i) {
                setTimeout( function() {
                    $('.cli-item-prx').eq(i).addClass('on');
                }, 150 * (i + 1));
            });
        }
    }); 

    $(window).scroll(function() {
        var wScroll = $(this).scrollTop();

        var wh = $(window).height();
        var value = wh/2;
        
        var mainSliOffset = $('.main-slider').offset().top;
        var mainBizOffset = $('.main-biz').offset().top;
        var mainCliOffset = $('.main-client').offset().top;
        var mainCaseOffset = $('.main-case').offset().top;
        var mainInqOffset = $('.main-inquiry').offset().top;
     
        if( wScroll > (mainSliOffset - value) )  {
            $('.indigator-prx li, .cli-prx').removeClass('on2'); 
            
            $('.indigator-prx').removeClass('on');
            $('.indigator-prx li').removeClass('on');
            $('.main-prx').addClass('on');
        } 
        
        if( wScroll > (mainBizOffset - value) )  {
            $('.indigator-prx li, .cli-prx').removeClass('on2');  

            $('.indigator-prx').addClass('on');
            $('.indigator-prx li').removeClass('on');
            $('.biz-prx').addClass('on');
        } 
        
        if(wScroll > (mainCliOffset - value)) {            
            $('.indigator-prx').addClass('on');
            $('.indigator-prx li').addClass('on on2');
            $('.cli-prx').addClass('on on2');
        }  

        if(wScroll > (mainCaseOffset - value)) {  
            $('.indigator-prx li, .cli-prx').removeClass('on2'); 

            $('.indigator-prx').addClass('on');
            $('.indigator-prx li').removeClass('on');
            $('.case-prx').addClass('on');
        }  

        if(wScroll > (mainInqOffset - value)) {            
            $('.indigator-prx').removeClass('on');
        }  
    });
     /* 메인 본문 END */  

    $('.indigator ul li a').click(function(e){
        e.preventDefault();

        var selected = $(this).attr('href');
        var position = $('#' + selected).offset().top + 'px';
       
        $('html').animate({
            scrollTop: position 
        });
    });

    $(document).on("click", function(event){
        var $trigger = $(".dropdown, .aside-dropdown, .footer-dropdown");
        if($trigger !== event.target && !$trigger.has(event.target).length){
            $(".dropdown-menu, .aside-menu, .footer-dropdown-menu").hide();
        }            
    });
});